import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { DoorsProvider } from './context/DoorsContext';

import DoorsPage from './pages/DoorsPage/DoorsPage';
import ShuttersPage from './pages/ShuttersPage/ShuttersPage';
import StartPage from './pages/StartPage';

import './App.scss';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/tapparelle" element={<ShuttersPage />} />
                <Route path="/porte" element={
                    <DoorsProvider>
                        <DoorsPage />
                    </DoorsProvider>
                } />
                {/* Rotta per 404 Not Found */}
                {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
        </Router>
    );
}

export default App;
