import React, { useContext } from 'react';

import { doorColors, doorSubtypes, doorTypes, fenestrationTypes, gridTypes, hingeColors, slidingTypes, yesNoOptions } from '../../constants/constants';
import { DoorsContext } from '../../context/DoorsContext';

export default function DoorDetailsStep({ onPrimaryButtonClick, onSecondaryButtonClick }) {
    const { doorOrder, setDoorOrder } = useContext(DoorsContext);

    const handleDoorTypeSelect = (doorType) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, doorType: doorType.label, slideType: '' }));
    };

    const handleDoorSubtypeSelect = (doorSubtype) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, doorSubtype: doorSubtype.label }));
    };

    const handleSlideTypeSelect = (slideType) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, slideType: slideType.label }));
    };

    const handleDoorColorSelect = (doorColor) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, doorColor: doorColor.label }));
    };

    const handleHingeColorSelect = (hingeColor) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, hingeColor: hingeColor.label }));
    };

    return (
        <div className='step door-detail-step'>
            <p className='f3 font-bold mt-0'>Dettagli Porta</p>
            <div className='door-type d-flex gap-24'>
                <p className='f4'>Tipo porta:</p>
                <div className='d-flex gap-24'>
                    {doorTypes.map((doorType, i) =>
                        <div key={i} className={`select-card ${doorOrder.doorType === doorType.value ? 'selected' : ''}`} onClick={() => handleDoorTypeSelect(doorType)}>
                            <p>{doorType.label}</p>
                        </div>
                    )}
                </div>
            </div>
            {doorOrder.doorType === 'Scorrevole' &&
                <div className='door-handle-type mt-32 d-flex gap-24'>
                    <p className='f4'>Tipo di scorrevole:</p>
                    <div className='d-flex gap-24'>
                        {slidingTypes.map((slideType, i) =>
                            <div key={i} className={`select-card ${doorOrder.slideType === slideType.value ? 'selected' : ''}`} onClick={() => handleSlideTypeSelect(slideType)}>
                                <p>{slideType.label}</p>
                            </div>
                        )}
                    </div>
                </div>
            }
            <div className='door-subtype mt-32 d-flex gap-24'>
                <p className='f4'>Sottotipo porta:</p>
                <div className='d-flex gap-24'>
                    {doorSubtypes.map((doorSubtype, i) =>
                        <div key={i} className={`select-card ${doorOrder.doorSubtype === doorSubtype.value ? 'selected' : ''}`} onClick={() => handleDoorSubtypeSelect(doorSubtype)}>
                            <p>{doorSubtype.label}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='door-color mt-32 d-flex gap-24'>
                <p className='f4'>Colore porta:</p>
                <div className='d-flex gap-24'>
                    {doorColors.map((doorColor, i) =>
                        <div key={i} className={`d-flex select-card color ${doorOrder.doorColor === doorColor.label ? 'selected' : ''}`} onClick={() => handleDoorColorSelect(doorColor)}>
                            <div className='color-circle' style={{ backgroundColor: doorColor.value }}></div>
                            <p>{doorColor.label}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='door-hinge mt-32 d-flex gap-24'>
                <p className='f4'>Colore cerniera:</p>
                <div className='d-flex gap-24'>
                    {hingeColors.map((hingeColor, i) =>
                        <div key={i} className={`d-flex select-card color ${doorOrder.hingeColor === hingeColor.label ? 'selected' : ''}`} onClick={() => handleHingeColorSelect(hingeColor)}>
                            <div className='color-circle' style={{ backgroundColor: hingeColor.value }}></div>
                            <p>{hingeColor.label}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='yes-no-select mt-32 d-flex gap-24'>
                <p className='f4'>Allargamento cassa:</p>
                <div className='d-flex gap-24'>
                    {yesNoOptions.map((option, i) =>
                        <div key={i} className={`select-card ${doorOrder.caseEnlargement === option.value ? 'selected' : ''}`} onClick={() => setDoorOrder(prevOrder => ({ ...prevOrder, caseEnlargement: option.label, turnsNumber: '' }))}>
                            <p>{option.label}</p>
                        </div>
                    )}
                </div>
            </div>
            {doorOrder.caseEnlargement === 'Si' &&
                <div className='input-wrapper mt-32'>
                    <label>Numero di giri:</label>
                    <input
                        name="turnsNumber"
                        className="input"
                        value={doorOrder.turnsNumber}
                        onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, turnsNumber: e.target.value }))}
                        type="text"
                        placeholder="Numero di giri"
                    />
                </div>
            }
            <div className='yes-no-select mt-32 d-flex gap-24'>
                <p className='f4'>Coprifilo:</p>
                <div className='d-flex gap-24'>
                    {yesNoOptions.map((option, i) =>
                        <div key={i} className={`select-card ${doorOrder.coprifilo === option.value ? 'selected' : ''}`} onClick={() => setDoorOrder(prevOrder => ({ ...prevOrder, coprifilo: option.label }))}>
                            <p>{option.label}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='yes-no-select mt-32 d-flex gap-24'>
                <p className='f4'>Finestratura:</p>
                <div className='d-flex gap-24'>
                    {yesNoOptions.map((option, i) =>
                        <div key={i} className={`select-card ${doorOrder.fenestration === option.value ? 'selected' : ''}`} onClick={() => setDoorOrder(prevOrder => ({ ...prevOrder, fenestration: option.label }))}>
                            <p>{option.label}</p>
                        </div>
                    )}
                </div>
            </div>
            {doorOrder.fenestration === 'Si' &&
                <div className='door-handle-type mt-32 d-flex gap-24'>
                    <p className='f4'>Tipo di finestratura:</p>
                    <div className='d-flex gap-24'>
                        {fenestrationTypes.map((fenestrationType, i) =>
                            <div key={i} className={`select-card ${doorOrder.fenestrationType === fenestrationType.value ? 'selected' : ''}`} onClick={() => setDoorOrder(prevOrder => ({ ...prevOrder, fenestrationType: fenestrationType.label }))}>
                                <p>{fenestrationType.label}</p>
                            </div>
                        )}
                    </div>
                </div>
            }
            <div className='yes-no-select mt-32 d-flex gap-24'>
                <p className='f4'>Griglia:</p>
                <div className='d-flex gap-24'>
                    {yesNoOptions.map((option, i) =>
                        <div key={i} className={`select-card ${doorOrder.grid === option.value ? 'selected' : ''}`} onClick={() => setDoorOrder(prevOrder => ({ ...prevOrder, grid: option.label }))}>
                            <p>{option.label}</p>
                        </div>
                    )}
                </div>
            </div>
            {doorOrder.grid === 'Si' &&
                <div className='door-handle-type mt-32 d-flex gap-24'>
                    <p className='f4'>Tipo di griglia:</p>
                    <div className='d-flex gap-24'>
                        {gridTypes.map((gridType, i) =>
                            <div key={i} className={`select-card ${doorOrder.gridType === gridType.value ? 'selected' : ''}`} onClick={() => setDoorOrder(prevOrder => ({ ...prevOrder, gridType: gridType.label }))}>
                                <p>{gridType.label}</p>
                            </div>
                        )}
                    </div>
                </div>
            }
            <div className='buttons mt-32 d-flex'>
                <button
                    className="secondary-button"
                    onClick={onSecondaryButtonClick}
                >
                    Indietro
                </button>
                <button
                    className="primary-button"
                    onClick={onPrimaryButtonClick}
                    disabled={false}
                >
                    Avanti
                </button>
            </div>
        </div>
    );
}

