import React, { useContext } from 'react';

import { DoorsContext } from '../../context/DoorsContext';
import './DoorSizeStep.scss';

export default function DoorSizeStep({ onPrimaryButtonClick, onSecondaryButtonClick }) {
    const { doorOrder, setDoorOrder } = useContext(DoorsContext);

    return (
        <div className='step door-size-step'>
            <p className='f3 font-bold mt-0'>Dimensioni e numero porte</p>
            <div className='d-flex mt-32 gap-24'>
                <div className='door-size-info'>
                    <div className='img-wrapper d-flex justify-content-center'>
                        <img src='/images/apertura_SX.png' alt="Apertura SX" />
                    </div>
                    <div className='input-wrapper mt-32'>
                        <label>Porte apertura SX</label>
                        <input
                            name="doorQtySx"
                            className="input"
                            value={doorOrder.doorQtySx}
                            onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, doorQtySx: e.target.value }))}
                            type="number"
                            placeholder="Numero di porte SX"
                        />
                    </div>
                </div>
                <div className='door-size-info'>
                    <div className='img-wrapper d-flex justify-content-center'>
                        <img src='/images/apertura_DX.png' alt="Apertura DX" />
                    </div>
                    <div className='input-wrapper mt-32'>
                        <label>Porte apertura DX</label>
                        <input
                            name="doorQtyDx"
                            className="input"
                            value={doorOrder.doorQtyDx}
                            onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, doorQtyDx: e.target.value }))}
                            type="number"
                            placeholder="Numero di porte DX"
                        />
                    </div>
                </div>
            </div>
            <div className='mt-32'>
                <p className='f4'>Misura foro porta:</p>
                <div className='d-flex gap-24 align-items-center'>
                    <div className='input-wrapper'>
                        <label>Larghezza (in cm)</label>
                        <input
                            name="length"
                            className="input"
                            value={doorOrder.length}
                            onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, length: e.target.value }))}
                            type="number"
                            placeholder="Larghezza"
                        />
                    </div>
                    <p className='f3'>X</p>
                    <div className='input-wrapper'>
                        <label>Altezza (in cm)</label>
                        <input
                            name="height"
                            className="input"
                            value={doorOrder.height}
                            onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, height: e.target.value }))}
                            type="number"
                            placeholder="Altezza"
                        />
                    </div>
                </div>
            </div>
            <div className='buttons mt-32 d-flex'>
                <button
                    className="secondary-button"
                    onClick={onSecondaryButtonClick}
                >
                    Indietro
                </button>
                <button
                    className="primary-button"
                    onClick={onPrimaryButtonClick}
                    disabled={true}
                >
                    Genera lista Taglio
                </button>
            </div>
        </div>
    );
}