import React, { useContext } from 'react';

import { doorColors, doorSubtypes, doorTypes, slidingTypes } from '../../constants/constants';
import { DoorsContext } from '../../context/DoorsContext';

export default function DoorHandleStep({ onPrimaryButtonClick, onSecondaryButtonClick }) {
    const { doorOrder, setDoorOrder } = useContext(DoorsContext);

    const handleDoorTypeSelect = (doorType) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, doorType: doorType.label, slideType: '' }));
    };

    const handleDoorSubtypeSelect = (doorSubtype) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, doorSubtype: doorSubtype.label }));
    };

    const handleSlideTypeSelect = (slideType) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, slideType: slideType.label }));
    };

    const handleDoorColorSelect = (doorColor) => {
        setDoorOrder(prevOrder => ({ ...prevOrder, doorColor: doorColor.label }));
    };

    return (
        <div className='step door-detail-step'>
            <p className='f3 font-bold mt-0'>Dettagli Maniglia</p>
            <div className='input-wrapper'>
                <label>Tipo maniglia:</label>
                <input
                    name="doorHandleType"
                    className="input"
                    value={doorOrder.doorHandleType}
                    onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, doorHandleType: e.target.value }))}
                    type="text"
                    placeholder="Tipo maniglia"
                />
            </div>
            <div className='input-wrapper mt-32'>
                <label>Colore maniglia:</label>
                <input
                    name="doorHandleColor"
                    className="input"
                    value={doorOrder.doorHandleColor}
                    onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, doorHandleColor: e.target.value }))}
                    type="text"
                    placeholder="Colore maniglia"
                />
            </div>
            <div className='buttons mt-32 d-flex'>
                <button
                    className="secondary-button"
                    onClick={onSecondaryButtonClick}
                >
                    Indietro
                </button>
                <button
                    className="primary-button"
                    onClick={onPrimaryButtonClick}
                    disabled={false}
                >
                    Avanti
                </button>
            </div>
        </div>
    );
}

