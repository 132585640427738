import React from 'react';
import { useNavigate } from 'react-router-dom';

import './StartPage.scss';

function StartPage() {
    const navigate = useNavigate();

    const handleTapparelleClick = () => {
        navigate('/tapparelle');
    };

    const handlePorteClick = () => {
        navigate('/porte');
    };

    return (
        <div className="content start-page">
            <p className='f2'>Seleziona l'applicativo che vuoi utilizzare:</p>
            <div className='cards-wrapper'>
                <div className='select-card' onClick={handleTapparelleClick}>
                    <p className='f2 font-bold'>Tapparelle</p>
                </div>
                <div className='select-card' onClick={handlePorteClick}>
                    <p className='f2 font-bold'>Porte</p>
                </div>
            </div>
        </div>
    );
}

export default StartPage;