import React, {useState, useRef} from 'react';
import {ExcelRenderer} from "react-excel-renderer";
import './OrderConfigurator.scss';

const initialState = {
    numTapparelle: '',
    numSteccheCieche: '',
    numSteccheAsolate: '',
    misuraTaglio: '',
    color: '',
}

export default function OrderConfigurator(props) {
    const numTapInput = useRef(null)
    const [row, setRow] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    function fileHandler(event) {
        let fileObj = event.target.files[0];
        setIsLoading(true);
        setError('');

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if(err){
                console.log(err);
                setError('Qualcosa è andato storto. Riprova')
            }
            else{
                props.onExcelImport(resp.rows)
                event.target.value = null;
                setIsLoading(false);
            }
        });

    }

    return (
        <div className="order-configurator">
            <div className="title">
                <span>Configuratore Ordine</span>
                <div className='input-wrapper'>
                    <label>Lungheza Stecca</label>
                    <input
                        name="lunghezzaStecca"
                        className="input"
                        value={props.lunghezzaStecca}
                        onChange={props.onChangeLunghezzaStecca}
                        type="number"
                        placeholder="Lunghezza Stecca(mm)"
                    />
                </div>
                <div className='input-wrapper' style={{marginLeft: '16px'}}>
                    <label>Max Ripetizioni</label>
                    <input
                        name="maxRipetizioni"
                        className="input"
                        value={props.maxRipetizioni}
                        onChange={props.onChangeMaxRipetizioni}
                        type="number"
                        placeholder="Ripetizioni Massime"
                    />
                </div>
            </div>
            <div className="subtitle">
                Inserire i parametri relativi all'ordine e cliccare sul pulsante invio per aggiungerli alla Scheda Ordine
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.onButtonClick(row);
                setRow(initialState);
                numTapInput.current.focus();
            }}>
                <div className="form-wrapper">
                    {/*<div className="header">*/}
                    {/*    <p>Num.Tapparelle</p>*/}
                    {/*    <p>Num.Stecche</p>*/}
                    {/*    <p>Misura Taglio</p>*/}
                    {/*</div>*/}
                    <div className="input-row">
                        <div className='input-wrapper'>
                            <label>Numero Tapparelle</label>
                            <input
                                onWheel={(e) => e.target.blur()}
                                ref={numTapInput}
                                name="numTapparelle"
                                className="input"
                                value={row.numTapparelle}
                                onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                                type="number"
                                placeholder="Inserisci il numero di tapparelle"
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label>Numero Stecche Cieche</label>
                            <input
                                onWheel={(e) => e.target.blur()}
                                name="numSteccheCieche"
                                className="input"
                                value={row.numSteccheCieche}
                                onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                                type="number"
                                placeholder="Inserisci il numero di stecche cieche"
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label>Numero Stecche Asolate</label>
                            <input
                                onWheel={(e) => e.target.blur()}
                                name="numSteccheAsolate"
                                className="input"
                                value={row.numSteccheAsolate}
                                onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                                type="number"
                                placeholder="Inserisci il numero di stecche asolate"
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label>Misura Taglio</label>
                            <input
                                onWheel={(e) => e.target.blur()}
                                name="misuraTaglio"
                                className="input"
                                value={row.misuraTaglio}
                                onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                                type="number"
                                placeholder="Inserisci la misura taglio (mm)"
                            />
                        </div>
                        {/* <input
                            name="color"
                            className="input"
                            value={row.color}
                            onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                            type="text"
                            placeholder="Colore"
                        /> */}
                    </div>
                </div>
                <div className="button-wrapper">
                    <button
                        className="configurator-button"
                        type="submit"
                        disabled={!row.numTapparelle || !row.numSteccheCieche || !row.numSteccheAsolate || !row.misuraTaglio}
                    >
                        Aggiungi
                    </button>
                    <div className="import-button">
                        <label className="configurator-button" htmlFor="file-input">{isLoading ? 'Loading...' : 'Importa da Excel'}</label>
                        <input id="file-input" type="file" accept=".xls,.xlsx" name="file" onChange={(e) => fileHandler(e)} style={{"display":"none"}} />
                    </div>
                </div>
                {error &&
                    <div className="error">{error}</div>
                }
            </form>
        </div>
    );
}

