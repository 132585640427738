import React, {useState, useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import './OrderSchedule.scss';

export default function OrderSchedule(props) {
    const [steccheCiecheNecessarie, setSteccheCiecheNecessarie] = useState(0);
    const [steccheAsolateNecessarie, setSteccheAsolateNecessarie] = useState(0);
    const [sprecoCieche, setSprecoCieche] = useState(0);
    const [sprecoAsolate, setSprecoAsolate] = useState(0);
    const [tagliRaggruppatiCieche, setTagliRaggruppatiCieche] = useState([]);
    const [tagliRaggruppatiAsolate, setTagliRaggruppatiAsolate] = useState([]);
    const [tagliRaggruppatiCiecheAsolate, setTagliRaggruppatiCiecheAsolate] = useState([]);
    const [numOrdine, setNumOrdine] = useState('');
    const [customer, setCustomer] = useState('');

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function sumSteccheRimanenti(array, type) {
        var steccheRimanenti = 0;
        for(var i=0; i<array.length; i++) {
            steccheRimanenti += array[i][type];
        }
        return steccheRimanenti;
    }

    // Funzione per raggruppare le tapperelle che hanno lo stesso numero di stecche cieche e asolate
    function getTapparelleStessoNumStecche(array) {
        var secondIndex = 0;
        var output = [];
        while(array.length > 0) {
            const firstElement = array[0];
            var index = array.length - 1;
            var newArray = []
            while (index >= 0) {
              if (array[index].numSteccheCieche === firstElement.numSteccheCieche && array[index].numSteccheAsolate === firstElement.numSteccheAsolate) {
                newArray.push(array[index]);
                array.splice(index, 1);
              }
              index -= 1;
            }
            output[secondIndex] = newArray;
            secondIndex ++;
        }
        return output;
    }

    // function findBestCombination(array, target) {
    //     const n = array.length;
    
    //     // Initialize a 2D array to store the possible combinations and remainders
    //     const dp = Array(n + 1)
    //         .fill(null)
    //         .map(() => Array(target + 1).fill(0));
    
    //     // Dynamic programming to fill the dp table
    //     for (let i = 1; i <= n; i++) {
    //         for (let j = 1; j <= target; j++) {
    //             if (array[i - 1] <= j) {
    //                 dp[i][j] = Math.max(
    //                     dp[i - 1][j],
    //                     dp[i - 1][j - array[i - 1]] + array[i - 1]
    //                 );
    //             } else {
    //                 dp[i][j] = dp[i - 1][j];
    //             }
    //         }
    //     }
    
    //     // Reconstruct the combination that achieves the target sum
    //     const combination = [];
    //     let i = n;
    //     let j = target;
    //     while (i > 0 && j > 0) {
    //         if (dp[i][j] !== dp[i - 1][j]) {
    //             combination.push(array[i - 1]);
    //             j -= array[i - 1];
    //         }
    //         i--;
    //     }
    
    //     // Calculate the remainder
    //     const remainder = target - dp[n][target];
    
    //     return { combination, remainder };
    // }

    function findBestCombination2(array, target, maxRepetitions) {
        // Inizializziamo una matrice per tenere traccia delle combinazioni
        const combinations = Array.from({ length: target + 1 }, () => []);
    
        // Inizializziamo il primo elemento della matrice
        combinations[0] = [[]];
    
        // Per ogni valore nell'array
        for (const num of array) {
            // Per ogni possibile somma fino al target
            for (let i = num; i <= target; i++) {
                // Per ogni combinazione parziale fino a questo punto
                for (const combination of combinations[i - num]) {
                    // Controlla se il valore è stato ripetuto troppe volte
                    if (countOccurrences(combination, num) < maxRepetitions) {
                        // Aggiungi la combinazione corrente al nuovo totale
                        combinations[i].push([...combination, num]);
                    }
                }
            }
        }
        const combinationsNoEmpty = rimuoviArrayVuoti(combinations);
        var combinationIndex = 1;
        var bestCombination = combinationsNoEmpty[combinationsNoEmpty.length - combinationIndex][0];
        var bestCombinationSum = bestCombination.reduce(function(accumulator, currentValue) {
            return accumulator + currentValue;
        }, 0);
        var waste = target - bestCombinationSum;
        while(waste < bestCombination.length*5) {
            combinationIndex++
            bestCombination = combinationsNoEmpty[combinationsNoEmpty.length - combinationIndex][0];
            bestCombinationSum = bestCombination.reduce(function(accumulator, currentValue) {
                return accumulator + currentValue;
            }, 0);
            waste = target - bestCombinationSum;
        }


        // Prendo la prima combinazione con scarto minore di 200
        // combinationsNoEmpty.forEach(function(array) {
        //     array.forEach(function(element) {
        //         console.log(element);
        //         var somma = element.reduce(function(accumulator, currentValue) {
        //             return accumulator + currentValue;
        //         }, 0);
        //         console.log(somma);
        //     });
        // });

        // Ritorna la migliore combinazione per il target
        return {combination: bestCombination, waste: waste};
    }
    
    // Funzione per contare le occorrenze di un valore in un array
    function countOccurrences(array, value) {
        return array.reduce((acc, cur) => acc + (cur === value ? 1 : 0), 0);
    } 

    function rimuoviArrayVuoti(matrice) {
        // Usa il metodo filter per filtrare solo gli array non vuoti
        return matrice.filter(function(array) {
          // Restituisci true solo se la lunghezza dell'array è maggiore di 0
          return array.length > 0;
        });
    }

    function ottimizzaAdvanced() {
        const lunghezzaStecca = props.lunghezzaStecca ? props.lunghezzaStecca : 6500; //La lunghezza della steccha da tagliare
        var tagliRaggruppatiCieche = []; //Raggruppo tutti i tagli uguali tra loro
        var tagliRaggruppatiAsolate = []; //Raggruppo tutti i tagli uguali tra loro
        var maxRepetitionsCieche = props.maxRipetizioni //Numero massimo in cui il taglio può essere ripetuto nella lunghezza della barra
        var maxRepetitionsAsolate = props.maxRipetizioni //Numero massimo in cui il taglio può essere ripetuto nella lunghezza della barra

        var order = [...props.order];

        //Calcolo il numero totale di stecche moltiplicando numero tapparelle per numero stecche
        order.map(row => {
            row.steccheCiecheRimanenti = row.numSteccheCieche * row.numTapparelle
            row.steccheAsolateRimanenti = row.numSteccheAsolate * row.numTapparelle
        })
        
        //Divido l'ordine raggruppando le tapperelle che hanno lo stesso numero di stecche cieche e asolate
        const tapparelleRaggruppate = getTapparelleStessoNumStecche(order);

        // console.log('TAPPARELLE RAGGRUPPATE', tapparelleRaggruppate);
        // tapparelleRaggruppate.forEach(taps => {
        //     //Estraggo i valori misuraTaglio e cerco la miglior combinazione con meno spreco
        //     const misuraTaglioValues = taps.map(item => parseInt(item.misuraTaglio));
        //     const bestCombination = findBestCombination2(misuraTaglioValues, lunghezzaStecca, 1);
        //     const numSteccheCieche = Number(taps[0].numSteccheCieche);
        //     const numSteccheAsolate = Number(taps[0].numSteccheAsolate);
        //     console.log('MIGLIOR COMBINAZIONE', bestCombination);
        //     //Se lo spreco è minore di 400mm
        //     if(bestCombination.waste < 400) {
        //         const bestCombinationSorted = bestCombination.combination.sort((a, b) => b - a);
        //         const bestCombinationString = bestCombinationSorted.join(' - ');
        //         const objCieche = {'value': bestCombinationString, 'number': numSteccheCieche, 'waste': bestCombination.waste*numSteccheCieche};
        //         tagliRaggruppatiCieche.push(objCieche);
        //         const objAsolate = {'value': bestCombinationString, 'number': numSteccheAsolate, 'waste': bestCombination.waste*numSteccheAsolate};
        //         tagliRaggruppatiAsolate.push(objAsolate);
        //         //Azzero le steccheRimanenti per la miglior combinazione
        //         bestCombination.combination.map(taglio => {
        //             const found = taps.find(item => item.misuraTaglio == taglio);
        //             found.steccheAsolateRimanenti = 0;
        //             found.steccheCiecheRimanenti = 0;
        //         });
        //     }
        // })
        // console.log('TAPPARELLE RAGGRUPPATE AGGIORNATE', tapparelleRaggruppate);

        //Creo un nuovo array unico rimuovendo le tapparelle che ho già completato
        var arrayUnicoTap = [].concat(...tapparelleRaggruppate);
        var arrayUnicoTapCieche = [...arrayUnicoTap];
        arrayUnicoTapCieche = arrayUnicoTapCieche.filter(array => array.steccheCiecheRimanenti > 0);
        var arrayUnicoTapAsolate = [...arrayUnicoTap];
        arrayUnicoTapAsolate = arrayUnicoTapAsolate.filter(array => array.steccheAsolateRimanenti > 0);
        console.log('ARRAY TAPPARELLE CIECHE', arrayUnicoTapCieche);
        console.log('ARRAY TAPPARELLE ASOLATE', arrayUnicoTapAsolate);

        var steccheTotaliCiecheDaProdurre = sumSteccheRimanenti(arrayUnicoTapCieche, 'steccheCiecheRimanenti');
        while(steccheTotaliCiecheDaProdurre > 0) {
            if(arrayUnicoTapCieche.length < 3) {
                maxRepetitionsCieche = 100;
            }
            //Cerco il miglior taglio e procedo finchè è possibile eseguire questo taglio per le stecche cieche
            const misuraTaglioValues = arrayUnicoTapCieche.map(item => parseInt(item.misuraTaglio));
            const bestCombinationWaste = findBestCombination2(misuraTaglioValues, lunghezzaStecca, maxRepetitionsCieche);
            console.log('MIGLIOR COMBINAZIONE / SPRECO CIECHE', bestCombinationWaste);
            //Verifico se c'è almeno una tapparella con 0 stecche cieche da produrre rimanenti
            //In tal caso devo procedere con un nuovo taglio
            // var tapNoSteccheCieche = arrayUnicoTapCieche.find(tap => tap.steccheCiecheRimanenti < 2); //DA VALUTARE QUALE USARE
            var tapNoSteccheCieche = arrayUnicoTapCieche.find(tap => tap.steccheCiecheRimanenti <= 0);
            var counterTagli = 0;
            while(!tapNoSteccheCieche) {
                bestCombinationWaste.combination.forEach(value => {
                    var tapDaModificare = arrayUnicoTapCieche.find(tap => Number(tap.misuraTaglio) === value);
                    if(tapDaModificare.steccheCiecheRimanenti > 0){
                        steccheTotaliCiecheDaProdurre--;
                    };
                    tapDaModificare.steccheCiecheRimanenti--;
                });
                counterTagli++;
                tapNoSteccheCieche = arrayUnicoTapCieche.find(tap => tap.steccheCiecheRimanenti <= 0);
                arrayUnicoTapCieche = arrayUnicoTapCieche.filter(tap => tap.steccheCiecheRimanenti > 0);
            } 
            const bestCombinationSorted = bestCombinationWaste.combination.sort((a, b) => b - a);
            const bestCombinationString = bestCombinationSorted.join(' - ');
            const objCieche = {'value': bestCombinationString, 'number': counterTagli, 'waste': bestCombinationWaste.waste*counterTagli};
            tagliRaggruppatiCieche.push(objCieche);
        }

        var steccheTotaliAsolateDaProdurre = sumSteccheRimanenti(arrayUnicoTapAsolate, 'steccheAsolateRimanenti');
        while(steccheTotaliAsolateDaProdurre > 0) {
            if(arrayUnicoTapAsolate.length < 3) {
                maxRepetitionsAsolate = 100;
            }
            //Cerco il miglior taglio e procedo finchè è possibile eseguire questo taglio per le stecche asolate
            const misuraTaglioValues = arrayUnicoTapAsolate.map(item => parseInt(item.misuraTaglio));
            const bestCombinationWaste = findBestCombination2(misuraTaglioValues, lunghezzaStecca, maxRepetitionsAsolate);
            console.log('MIGLIOR COMBINAZIONE / SPRECO ASOLATE', bestCombinationWaste);
            //Verifico se c'è almeno una tapparella con 0 stecche cieche da produrre rimanenti
            //In tal caso devo procedere con un nuovo taglio
            var tapNoSteccheAsolate = arrayUnicoTapAsolate.find(tap => tap.steccheAsolateRimanenti <= 0);
            var counterTagli = 0;
            while(!tapNoSteccheAsolate) {
                bestCombinationWaste.combination.forEach(value => {
                    var tapDaModificare = arrayUnicoTapAsolate.find(tap => Number(tap.misuraTaglio) === value);
                    if(tapDaModificare.steccheAsolateRimanenti > 0){
                        steccheTotaliAsolateDaProdurre--;
                    };
                    tapDaModificare.steccheAsolateRimanenti--;
                });
                counterTagli++;
                tapNoSteccheAsolate = arrayUnicoTapAsolate.find(tap => tap.steccheAsolateRimanenti <= 0);
                arrayUnicoTapAsolate = arrayUnicoTapAsolate.filter(tap => tap.steccheAsolateRimanenti > 0);
            } 
            const bestCombinationSorted = bestCombinationWaste.combination.sort((a, b) => b - a);
            const bestCombinationString = bestCombinationSorted.join(' - ');
            const objAsolate = {'value': bestCombinationString, 'number': counterTagli, 'waste': bestCombinationWaste.waste*counterTagli};
            tagliRaggruppatiAsolate.push(objAsolate);
        }

        //Vedo se ci sono tagli uguali sia per le cieche che per le asolate e li raggruppo sommando il numero e lo spreco
        const newTagliRaggruppatiCieche = raggruppaTagliArray(tagliRaggruppatiCieche);
        const newTagliRaggruppatiAsolate = raggruppaTagliArray(tagliRaggruppatiAsolate);

        setTagliRaggruppatiCieche(newTagliRaggruppatiCieche);
        setTagliRaggruppatiAsolate(newTagliRaggruppatiAsolate);
        const sprecoCieche = tagliRaggruppatiCieche.reduce((total, item) => {
            return total + item.waste
        }, 0);
        const sprecoAsolate = tagliRaggruppatiAsolate.reduce((total, item) => {
            return total + item.waste
        }, 0);
        setSprecoCieche(sprecoCieche);
        setSprecoAsolate(sprecoAsolate);
        raggruppaTagliCiecheAsolate(newTagliRaggruppatiCieche, newTagliRaggruppatiAsolate);

        console.log('TAPPARELLE ORDINE UNICO CIECHE', arrayUnicoTapCieche);
        console.log('TAPPARELLE ORDINE UNICO ASOLATE', arrayUnicoTapAsolate);
        console.log('-----------------------------------------');
        console.log('STECCHE CIECHE DA PRODURRE', steccheTotaliCiecheDaProdurre);
        console.log('STECCHE ASOLATE DA PRODURRE', steccheTotaliAsolateDaProdurre);
        console.log('-----------------------------------------');
        console.log('TAGLI RAGGRUPPATI CIECHE', newTagliRaggruppatiCieche);
        console.log('TAGLI RAGGRUPPATI ASOLATE', newTagliRaggruppatiAsolate);
    }

    function ottimizzaBasic() {
        const lunghezzaStecca = props.lunghezzaStecca ? props.lunghezzaStecca : 6500; //La lunghezza della steccha da tagliare
        const taglioLama = 5; //Spreco che si ha durante ogni taglio
        var counterSteccheCieche = 0; //Contatore di stecche necessarie per l'ordine
        var counterSteccheAsolate = 0; //Contatore di stecche necessarie per l'ordine
        var sprecoCieche = 0; //Spreco Cieche
        var sprecoAsolate = 0; //Spreco Asolate
        var indicazioniTagliCieche = []; //Indica come tagliare ogni sbarra cieca
        var tagliRaggruppatiCieche = []; //Raggruppo tutti i tagli uguali tra loro
        var indicazioniTagliAsolate = []; //Indica come tagliare ogni sbarra cieca
        var tagliRaggruppatiAsolate = []; //Raggruppo tutti i tagli uguali tra loro

        var order = [...props.order];

        //Calcolo il numero totale di stecche moltiplicando numero tapparelle per numero stecche
        order.map(row => {
            row.steccheCiecheRimanenti = row.numSteccheCieche * row.numTapparelle
            row.steccheAsolateRimanenti = row.numSteccheAsolate * row.numTapparelle
        })
        
        //Ordino le righe in ordine decrescente per la misura taglio
        var sortedOrder = order.sort((a, b) => {
            if(Number(a.misuraTaglio) < Number(b.misuraTaglio))
                return 1;
            return -1;
        })

        //Continuo finchè mi restano 0 stecche da produrre
        var steccheTotaliCiecheDaProdurre = sumSteccheRimanenti(order, 'steccheCiecheRimanenti');
        var steccheTotaliAsolateDaProdurre = sumSteccheRimanenti(order, 'steccheAsolateRimanenti');
        while(steccheTotaliCiecheDaProdurre > 0) {
            var lunghezzaSteccaRimanente = lunghezzaStecca;
            var tagli = [];
            var tagliStringa = '';
            sortedOrder.map(row => {
                while (Number(lunghezzaSteccaRimanente) > Number(row.misuraTaglio) && row.steccheCiecheRimanenti > 0) {
                    lunghezzaSteccaRimanente = lunghezzaSteccaRimanente - row.misuraTaglio - taglioLama;
                    row.steccheCiecheRimanenti--;
                    steccheTotaliCiecheDaProdurre--;
                    tagli.push(row.misuraTaglio);
                    tagliStringa += row.misuraTaglio + ' - ';
                }
            })

            indicazioniTagliCieche.push(tagliStringa.substring(0, tagliStringa.length - 3));
            counterSteccheCieche++;
            sprecoCieche += lunghezzaSteccaRimanente;
        }

        while(steccheTotaliAsolateDaProdurre > 0) {
            var lunghezzaSteccaRimanente = lunghezzaStecca;
            var tagli = [];
            var tagliStringa = '';
            sortedOrder.map(row => {
                while (Number(lunghezzaSteccaRimanente) > Number(row.misuraTaglio) && row.steccheAsolateRimanenti > 0) {
                    lunghezzaSteccaRimanente = lunghezzaSteccaRimanente - row.misuraTaglio - taglioLama;
                    row.steccheAsolateRimanenti--;
                    steccheTotaliAsolateDaProdurre--;
                    tagli.push(row.misuraTaglio);
                    tagliStringa += row.misuraTaglio + ' - ';
                }
            })

            indicazioniTagliAsolate.push(tagliStringa.substring(0, tagliStringa.length - 3));
            counterSteccheAsolate++;
            sprecoAsolate += lunghezzaSteccaRimanente;
        }
        console.log('TAGLI CIECHE', indicazioniTagliCieche);
        console.log('TAGLI ASOLATE', indicazioniTagliAsolate);

        var occurrencesCieche = { };
        for (var i = 0, j = indicazioniTagliCieche.length; i < j; i++) {
            occurrencesCieche[indicazioniTagliCieche[i]] = (occurrencesCieche[indicazioniTagliCieche[i]] || 0) + 1;
        }

        //Raggruppo i duplicati per le cieche
        for (const [key, value] of Object.entries(occurrencesCieche)) {
            const obj = {'value': key, 'number': value};
            tagliRaggruppatiCieche.push(obj);
        }

        var occurrencesAsolate = { };
        for (var i = 0, j = indicazioniTagliAsolate.length; i < j; i++) {
            occurrencesAsolate[indicazioniTagliAsolate[i]] = (occurrencesAsolate[indicazioniTagliAsolate[i]] || 0) + 1;
        }

        //Raggruppo i duplicati per le asolate
        for (const [key, value] of Object.entries(occurrencesAsolate)) {
            const obj = {'value': key, 'number': value};
            tagliRaggruppatiAsolate.push(obj);
        }
        console.log('TAGLI RAGGRUPPATI CIECHE', tagliRaggruppatiCieche);
        console.log('TAGLI RAGGRUPPATI ASOLATE', tagliRaggruppatiAsolate);

        setTagliRaggruppatiCieche(tagliRaggruppatiCieche);
        setTagliRaggruppatiAsolate(tagliRaggruppatiAsolate);
        setSteccheCiecheNecessarie(counterSteccheCieche);
        setSteccheAsolateNecessarie(counterSteccheAsolate);
        setSprecoCieche(sprecoCieche);
        setSprecoAsolate(sprecoAsolate);

        raggruppaTagliCiecheAsolate(tagliRaggruppatiCieche, tagliRaggruppatiAsolate)
    }

    function raggruppaTagliCiecheAsolate(tagliCieche, tagliAsolate) {
        const tagliCiecheAsolateArray = [];

        //Itero l'array dei tagli delle stecche cieche
        tagliCieche.forEach(cieca => {
            const matchedItem = tagliAsolate.find(asolata => asolata.value === cieca.value);
            //Se il taglio corrisponde a quello delle asolate faccio un raggruppamento
            if(matchedItem) {
                const obj = {
                    value: matchedItem.value,
                    numberCieche: cieca.number,
                    numberAsolate: matchedItem.number
                }
                tagliCiecheAsolateArray.push(obj);
            //Altrimenti lo inserisco lo stesso nel nuovo array con il numero corrispondente per le cieche e 0 per le asolate
            } else {
                const obj = {
                    value: cieca.value,
                    numberCieche: cieca.number,
                    numberAsolate: 0
                }
                tagliCiecheAsolateArray.push(obj);
            }
        })

        //Controllo nell'array dei tagli delle stecche asolate per vedere se c'è ancora qualche taglio non presente nell'array raggruppato
        const tagliAsolateMancanti = tagliAsolate.filter(({ value: id1 }) => !tagliCiecheAsolateArray.some(({ value: id2 }) => id2 === id1));
        tagliAsolateMancanti.forEach(asolata => {
            const obj = {
                value: asolata.value,
                numberCieche: 0,
                numberAsolate: asolata.number
            }
            tagliCiecheAsolateArray.push(obj);
        })
        const steccheCiecheNecessarie = tagliCiecheAsolateArray.reduce((total, item) => {
            return total + item.numberCieche
        }, 0);
        const steccheAsolateNecessarie = tagliCiecheAsolateArray.reduce((total, item) => {
            return total + item.numberAsolate
        }, 0);
        setSteccheCiecheNecessarie(steccheCiecheNecessarie);
        setSteccheAsolateNecessarie(steccheAsolateNecessarie);
        setTagliRaggruppatiCiecheAsolate(tagliCiecheAsolateArray);
    }

    const raggruppaTagliArray = (data) => {
        const result = {};

        data.forEach(item => {
            if (!result[item.value]) {
                result[item.value] = { number: 0, waste: 0 };
            }
            result[item.value].number += item.number;
            result[item.value].waste += item.waste;
        });

        return Object.keys(result).map(key => ({
            value: key,
            number: result[key].number,
            waste: result[key].waste
        }));
    };

    return (
        <div className="order-schedule">
            <div className="title">
                Scheda Ordine
            </div>
            <div className="customer-input">
                <input
                    name="numOrdine"
                    className="input"
                    value={numOrdine}
                    onChange={(e) => {setNumOrdine(e.target.value)}}
                    type="text"
                    placeholder="Numero Ordine"
                    style={{marginRight: '8px'}}
                />
                <input
                    name="customer"
                    className="input"
                    value={customer}
                    onChange={(e) => {setCustomer(e.target.value)}}
                    type="text"
                    placeholder="Nome Cliente"
                    style={{marginLeft: '8px'}}
                />
            </div>
            <div className="order-schedule-content">
                <div className="header">
                    <p>Num. Tapparelle</p>
                    <p>Num. Stecche Cieche</p>
                    <p>Num. Stecche Asolate</p>
                    <p>Misura Taglio</p>
                    {/* <p>Colore</p> */}
                    <p className="delete-icon"></p>
                </div>
                <div className="items">
                    {props.order.map((orderRow, i) =>
                        <div className="items-row" key={i}>
                            <p>{orderRow.numTapparelle}</p>
                            <p>{orderRow.numSteccheCieche}</p>
                            <p>{orderRow.numSteccheAsolate}</p>
                            <p>{orderRow.misuraTaglio} mm</p>
                            {/* <p>{orderRow.color}</p> */}
                            <p className="delete-icon">
                                <span onClick={() => props.onRowDelete(i)}>X</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="order-schedule-button">
                {/* <button
                    className="configurator-button"
                    onClick={ottimizzaBasic}
                    disabled={!props.order.length}
                >
                    Ottimizza
                </button> */}
                <button
                    className="configurator-button"
                    onClick={ottimizzaAdvanced}
                    disabled={!props.order.length}
                >
                    Ottimizza
                </button>
                <button
                    className="reset-button"
                    onClick={() => {
                        setSprecoCieche(0);
                        setSprecoAsolate(0);
                        setSteccheCiecheNecessarie(0);
                        setSteccheAsolateNecessarie(0);
                        setTagliRaggruppatiCieche([]);
                        setTagliRaggruppatiAsolate([]);
                        setTagliRaggruppatiCiecheAsolate([]);
                        props.onReset()
                    }}
                >
                    Reset
                </button>
                <button
                    className="print-button"
                    onClick={handlePrint}
                >
                    Stampa
                </button>
            </div>
            <div id="print-wrapper" className="order-schedule-result" ref={componentRef}>
                <p><b>ID Ordine:</b> {numOrdine} / <b>Cliente:</b> {customer}</p>
                <p><b>Lunghezza Stecca:</b> {props.lunghezzaStecca ? props.lunghezzaStecca : 6500} mm</p>
                <p><b>Barre Cieche Necessarie:</b> {steccheCiecheNecessarie} / <b>Spreco:</b> {sprecoCieche/1000} m</p>
                <p><b>Barre Asolate Necessarie:</b> {steccheAsolateNecessarie} / <b>Spreco:</b> {sprecoAsolate/1000} m</p>
                <table cellspacing="0" rules="all" border="1">
                    <tr>
                        <th>Num. Tapparelle</th>
                        <th>Num. Stecche Cieche</th>
                        <th>Num. Stecche Asolate</th>
                        <th>Misura Taglio</th>
                        {/* <th>Colore</th> */}
                    </tr>
                    {props.order.map((orderRow, i) =>
                        <tr key={i}>
                            <td>{orderRow.numTapparelle}</td>
                            <td>{orderRow.numSteccheCieche}</td>
                            <td>{orderRow.numSteccheAsolate}</td>
                            <td>{orderRow.misuraTaglio} mm</td>
                            {/* <td>{orderRow.color}</td> */}
                        </tr>
                    )}
                </table>
                {/* <div className="order-schedule-cut">
                    <p style={{marginTop: '40px'}}>Tagliare le barre cieche come segue: </p>
                    {tagliRaggruppatiCieche.map((tagli, i) =>
                        <p>
                            <strong>{tagli.number} Barre: </strong> 
                            <span>{tagli.value} </span> 
                            <strong>({tagli.value.split('-').length} tagli)</strong>
                        </p>
                    )}
                </div>
                <div className="order-schedule-cut">
                    <p style={{marginTop: '40px'}}>Tagliare le barre asolate come segue: </p>
                    {tagliRaggruppatiAsolate.map((tagli, i) =>
                        <p>
                            <strong>{tagli.number} Barre: </strong> 
                            <span>{tagli.value} </span>
                            <strong>({tagli.value.split('-').length} tagli)</strong>
                        </p>
                    )}
                </div> */}
                <div className="order-schedule-cut">
                    <p style={{marginTop: '40px'}}>Riepilogo tagli: </p>
                    {tagliRaggruppatiCiecheAsolate.map((tagli, i) =>
                        <p>
                            {tagli.numberCieche !== 0 &&
                                <strong>{tagli.numberCieche} Barre Cieche</strong> 
                            }
                            {tagli.numberCieche !== 0 && tagli.numberAsolate !== 0 && 
                                <span> + </span>
                            }
                            {tagli.numberAsolate !== 0 &&
                                <strong> {tagli.numberAsolate} Barre Asolate</strong> 
                            }
                            : <span>{tagli.value} </span>
                            <strong>({tagli.value.split('-').length} tagli)</strong>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

