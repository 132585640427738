import React, { useState } from 'react';
import OrderConfigurator from '../../components/OrderConfigurator/OrderConfigurator';
import OrderSchedule from '../../components/OrderSchedule/OrderSchedule';
import './ShuttersPage.scss';

function ShuttersPage() {
    const [order, setOrder] = useState([]);
    const [lunghezzaStecca, setLunghezzaStecca] = useState(6500);
    const [maxRipetizioni, setMaxRipetizioni] = useState(2);

    function addToOrder(row) {
        setOrder([...order, row]);
    }

    function addMultipleRowsToOrder(rows) {
        var rowsArray = []
        for (var i = 0; i < rows.length; i++) {
            if (i > 0 && rows[i][0] && rows[i][2] && rows[i][3]) {
                var row = {
                    'numTapparelle': rows[i][0],
                    'numSteccheCieche': rows[i][1],
                    'numSteccheAsolate': rows[i][2],
                    'misuraTaglio': rows[i][3]
                }
                rowsArray.push(row);
            }
        }
        setOrder(rowsArray);
    }

    function deleteRow(index) {
        const orderCopy = [...order];
        orderCopy.splice(index, 1);
        setOrder(orderCopy);
    }

    return (
        <div className="content shutters-page">
            <OrderConfigurator
                lunghezzaStecca={lunghezzaStecca}
                maxRipetizioni={maxRipetizioni}
                onChangeLunghezzaStecca={(e) => setLunghezzaStecca(e.target.value)}
                onChangeMaxRipetizioni={(e) => setMaxRipetizioni(e.target.value)}
                onExcelImport={addMultipleRowsToOrder}
                onButtonClick={addToOrder}
            />
            <OrderSchedule
                order={order}
                lunghezzaStecca={lunghezzaStecca}
                maxRipetizioni={maxRipetizioni}
                onReset={() => setOrder([])}
                onRowDelete={(i) => deleteRow(i)}
            />
        </div>
    );
}

export default ShuttersPage;
